import Link from "next/link";
import Footer_Dis from "../../styles/Footer_Dis.module.css";
export default function FooterDis() {
  return (
    <div className="dis-container">
        <div className="thumb-dis">
          <div className={Footer_Dis.bottomfooter}>

           <div className={Footer_Dis.Container}>
             <h3 className="contant name">Top Games </h3>
             <ul className="gamelist">
             <li className="para description"><Link href={"/game/besties-reunion-party"} prefetch={false}><a title={"Besties Reunion Party"}>{"Besties Reunion Party"}</a></Link></li>
             <li className="para description"><Link href={"/game/ellie-summer-spa-and-beauty-salon"} prefetch={false}><a title={"Ellie Summer Spa And Beauty Salon"}>{"Ellie Summer Spa And Beauty Salon"}</a></Link></li>
             <li className="para description"><Link href={"/game/panda-tropical-wedding-story"} prefetch={false}><a title={"Panda Tropical Wedding Story"}>{"Panda Tropical Wedding Story"}</a></Link></li>
             <li className="para description"><Link href={"/game/avatar-the-way-of-love"} prefetch={false}><a title={"Avatar The Way Of Love"}>{"Avatar The Way Of Love"}</a></Link></li>
             <li className="para description"><Link href={"/game/angela-valentine-story-deep-water"} prefetch={false}><a title={"Angela valentine Story Deep Water"}>{"Angela valentine Story Deep Water"}</a></Link></li>
             <li className="para description"><Link href={"/game/ellie-easter-adventure"} prefetch={false}><a title={"Ellie Easter Adventure"}>{"Ellie Easter Adventure"}</a></Link></li>
             <li className="para description"><Link href={"/game/mr-and-mrs-santa-christmas-adventure"} prefetch={false}><a title={"Mr and Mrs Santa Christmas Adventure"}>{"Mr and Mrs Santa Christmas Adventure"}</a></Link></li>
             <li className="para description"><Link href={"/game/my-perfect-halloween-costume"} prefetch={false}><a title={"My Perfect Halloween Costume"}>{"My Perfect Halloween Costume"}</a></Link></li>
             <li className="para description"><Link href={"/game/little-lily-halloween-prep"} prefetch={false}><a title={"Little Lily Halloween Prep"}>{"Little Lily Halloween Prep"}</a></Link></li>
             <li className="para description"><Link href={"/game/celebrity-first-date-adventure"} prefetch={false}><a title={"Celebrity First Date Adventure"}>{"Celebrity First Date Adventure"}</a></Link></li>
             </ul>
           </div>
           <div className={Footer_Dis.Container}>
             <h3 className="contant name">Categories</h3>
             <ul className="gamelist">
             <li className="para description"><Link href={"/categories/play-cute-games"} prefetch={false}><a title={"Play Cute Games"}>{"Play Cute Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/cooking-games"} prefetch={false}><a title={"Cooking Games"}>{"Cooking Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/doctor-games"} prefetch={false}><a title={"Doctor Games"}>{"Doctor Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/decoration-games"} prefetch={false}><a title={"Decoration Games"}>{"Decoration Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/christmas-games"} prefetch={false}><a title={"Christmas games"}>{"Christmas games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/friday-night-funkin"} prefetch={false}><a title={"Friday Night Funkin Games"}>{"Friday Night Funkin Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/my-dolphin-show"} prefetch={false}><a title={"My Dolphin Show"}>{"My Dolphin Show"}</a></Link></li>
            <li className="para description"><Link href={"/categories/match-3-games"} prefetch={false}><a title={"Match 3 Games"}>{"Match 3 Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/nail-games"} prefetch={false}><a title={"Nail Games"}>{"Nail Games"}</a></Link></li>
             </ul>
           </div>
           <div className={Footer_Dis.Container}>
             <h3 className="contant name">Games for Girls</h3>
             <ul className="gamelist">
             <li className="para description"><Link href={"/categories/dress-up-games"} prefetch={false}><a title={"Dress up Games"}>{"Dress up Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/makeover-games"} prefetch={false}><a title={"Makeover Games"}>{"Makeover Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/shopaholic-games"} prefetch={false}><a title={"Shopaholic Games"}>{"Shopaholic Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/fashion-games"} prefetch={false}><a title={"Fashion Games"}>{"Fashion Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/pony-games"} prefetch={false}><a title={"Pony Games"}>{"Pony Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/barbie-dress-up"} prefetch={false}><a title={"Barbie dress up"}>{"Barbie dress up"}</a></Link></li>
            <li className="para description"><Link href={"/categories/celebrity-games"} prefetch={false}><a title={"Celebrity Games"}>{"Celebrity Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/wedding-games"} prefetch={false}><a title={"Wedding Games"}>{"Wedding Games"}</a></Link></li>
            <li className="para description"><Link href={"/categories/mermaid-games"} prefetch={false}><a title={"Mermaid Games"}>{"Mermaid Games"}</a></Link></li>
             </ul>
           </div>
           <div className={Footer_Dis.Container}>
             <h3 className="contant name">Webmasters</h3>
             <ul className="gamelist">
             <li className="para description"><Link href={"/free-games-for-your-site"} prefetch={false}><a title={"Free games for your site"}>{"Free games for your site"}</a></Link></li>
            <li className="para description"><Link href={"/contact-us"} prefetch={false}><a title={" Contact Us "}>{" Contact Us "}</a></Link></li>
            <li className="para description"><Link href={"/privacy-policy"} prefetch={false}><a title={"Privacy Policy"}>{"Privacy Policy"}</a></Link></li>
            <li className="para description"><Link href={"/our-partners"} prefetch={false}><a title={"Our Partners"}>{"Our Partners"}</a></Link></li>
            
             </ul>
           </div>

          </div>
        </div>
      </div>
  );
}
